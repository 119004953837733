import { NotiflixService } from './../../services/notiflix.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap, interval } from 'rxjs';
import { environment } from '../../environments/environment';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private urlApi = environment.urlApi;

  private refreshThreshold = 110;

  constructor(private httpClient: HttpClient, private router: Router, private notiflixService:NotiflixService) {}

  startTokenRefreshCountdown() {
    if (!this.expires_at) {
      return;
    }

    let date2 = moment();
    let date1 = moment(this.expires_at);
    let differenceInMs = date1.diff(date2);
    let diff = moment.duration(differenceInMs).asSeconds();

    if (diff > 0) {
      interval(1000)
        .pipe(
          concatMap(async () => {
            if (diff <= this.refreshThreshold) {
              await this.refreshToken();
              let date2 = moment();
              let date1 = moment(this.expires_at);
              let differenceInMs = date1.diff(date2);
              diff = moment.duration(differenceInMs).asSeconds() + 5;
            } else {
              diff -= 1;
            }
          })
        )
        .subscribe();
    } else {
      this.refreshToken();
    }
  }

  refreshToken() {
    const url = `${this.urlApi}/refresh`;

    this.httpClient.post<any>(url, {}).subscribe(
      (response: any) => {
        this.token = response.access_token;
        this.expires_in = response.expires_in;
        this.expires_at = response.expires_at;
        this.notiflixService.notify().info('Sessão restaurada!')
      },
      (error) => {
        this.deleteSession();
        this.router.navigate(['/login']);
      }
    );
  }

  deleteSession() {
    localStorage.clear();
  }

  get isAuthenticated(): boolean {
    return this.token ? true : false;
  }

  set token(access_token: string) {
    localStorage.setItem('token', access_token);
  }

  get token(): string | null {
    return localStorage.getItem('token') ?? null;
  }

  set expires_at(expires_at: string) {
    localStorage.setItem('expires_at', expires_at);
  }

  get expires_at(): string | null {
    return localStorage.getItem('expires_at') ?? null;
  }

  set expires_in(expires_in: number) {
    const expires_in_sec = expires_in * 60;
    localStorage.setItem('expires_in', String(expires_in_sec));
  }

  get expires_in(): string | null {
    return localStorage.getItem('expires_in') ?? null;
  }

  set me(e:any){
    localStorage.setItem('me', JSON.stringify(e));
  }

  get me(){
    const me = localStorage.getItem('me');
    return me ? JSON.parse(me) : null;
  }

  get roles(){
    const me = localStorage.getItem('me');
    return me ? JSON.parse(me).roles : null;
  }

  get branch(){
    const branch = localStorage.getItem('branch');
    return branch ? JSON.parse(branch) : null;
  }

  set branch(branch:any){
    localStorage.setItem('branch', JSON.stringify(branch));
  }

  get env(){
    return environment.mode
  }
}
